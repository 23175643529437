import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import EditAddress from 'components/common/EditAddress';
import TimeInput from 'components/common/TimeInput';

//-----------------------------------------------------------------

class EditUserModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                firstName: {
                    label: 'First Name',
                    type: 'text'
                },
                lastName: {
                    label: 'Last Name',
                    type: 'text'
                },
                nickname: {
                    label: 'Display Name',
                    type: 'text'
                },
                emailAddress: {
                    label: 'Email Address',
                    type: 'email'
                },
                isSweep: {
                    boxLabel: 'User is a sweep',
                    type: 'checkbox'
                },
                isOffice: {
                    boxLabel: 'User is office staff',
                    type: 'checkbox'
                },
                canLogIn: {
                    boxLabel: 'User can log into ProSweep',
                    type: 'checkbox'
                },
                username: {
                    label: 'Login Name',
                    type: 'text'
                },
                newPassword: {
                    label: 'Change password to...',
                    type: 'text'
                },
                diaryBackColour: {
                    label: 'Diary Colour (Background)',
                    type: 'color'
                },
                diaryTextColour: {
                    label: 'Diary Colour (Text)',
                    type: 'color'
                },
                isDifferentBaseAddress: {
                    boxLabel: 'Base Address different to Home Address',
                    type: 'checkbox'
                },
                newPassword: {
                    label: 'New Password',
                    type: 'password'
                },
                hetasNumber: {
                    label: 'HETAS Number',
                    type: 'text'
                },
                apicsNumber: {
                    label: 'APICS Number',
                    type: 'text'
                },
                isSFAApproved: {
                    boxLabel: 'SFA Approved',
                    type: 'checkbox'
                },
                isNVQApproved: {
                    boxLabel: 'NVQ Approved',
                    type: 'checkbox'
                },
                excludeFromApptSuggestions: {
                    boxLabel: 'Exclude from Suggested Dates/Times',
                    type: 'checkbox'
                },
                isAvailableOB: {
                    boxLabel: 'Available for Online Booking',
                    type: 'checkbox'
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const {
            id
        } = this.state;

        // Load user
        let user;
        if (id) {
            user = await API.call('user/get/' + id);
        } else {
            user = {
                diaryBackColour: '#eeeeee',
                diaryTextColour: '#000000',
                rotas: {}
            };
        }

        // Update
        this.setState({
            user,
            isLoading: false
        });
    }
    
    getFieldValue(fieldName) {
        return this.state.user[fieldName];
    }

    updateFields(fields, callback) {
        const user = { ...this.state.user };
        let updateUsername;
        for (let fieldName in fields) {
            const value = fields[fieldName];
            user[fieldName] = value;

            // Run extra logic
            if (fieldName == 'firstName') {
                user.nickname = user.firstName;
                updateUsername = true;
            } else if (fieldName == 'lastName') {
                updateUsername = true;
            }
        }
        if (updateUsername && !user.id) {
            user.username = ((user.firstName || '') + ' ' + (user.lastName || '')).trim().toLowerCase().replace(' ', '.');
        }
        this.setState({ user }, callback);
    }

    updateAddressFields(addressType, fields, callback) {
        const address = { ...this.state.user[addressType] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            address[fieldName] = value;
        }
        this.updateFields({ [addressType]: address }, callback);
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        await API.call('user/save', this.state.user);
        this.close();
        this.resolve();
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this user?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('user/delete/' + this.state.id);
            this.close();
            this.resolve();
        }
    }

    updateRota(dow, fields) {
        const rotas = { ...this.state.user.rotas };
        const rotaDay = { ...rotas[dow] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            rotaDay[fieldName] = value;
        }
        rotas[dow] = rotaDay;
        this.updateFields({ rotas });
    }

    addRotaDay(dow) {
        const rotas = { ...this.state.user.rotas };
        const rotaDay = {
            dayOfWeek: dow
        };
        rotas[dow] = rotaDay;
        this.updateFields({ rotas });
    }

    removeRotaDay(dow) {
        const rotas = { ...this.state.user.rotas };
        delete rotas[dow];
        this.updateFields({ rotas });
    }

    render() {
        const { 
            isOpen,
            isLoading,
            id,
            user
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className={`edit-user-modal ${user && user.isSweep ? 'wider' : ''}`} backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault() }}>
                        {/*
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Cancel
                            </button>
                            {!!id &&
                                <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                                    Delete
                                </button>
                            }
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        const { user } = this.state;

        if (user.isSweep) {
            
            return (<>

                <div className="row">

                    <div className="col-md-6">
                        
                        {this.renderMainSection()}

                    </div>

                    <div className="col-md-6">

                        {this.renderSweepSection()}

                    </div>

                </div>

            </>);
            
        } else {
            
            return this.renderMainSection();

        }
    }

    renderMainSection() {
        const { user } = this.state;
        
        return (<>

            <fieldset>

                <h2>Main Details</h2>

                {this.form.render([
                    'firstName',
                    'lastName',
                    'nickname',
                    'emailAddress',
                    'isSweep',
                    'isOffice',
                    'canLogIn',
                    (user.canLogIn && 'username'),
                    (user.canLogIn && 'newPassword')
                ])}

            </fieldset>

        </>);
    }

    renderSweepSection() {
        const { user } = this.state;
        const { loginInfo } = this.props;

        return (<>

            <fieldset>

                <h2>Sweep Details</h2>

                {this.form.render([
                    'diaryBackColour',
                    'diaryTextColour',
                    'hetasNumber',
                    'apicsNumber',
                    'isSFAApproved',
                    'isNVQApproved',
                    'excludeFromApptSuggestions',
                    'isAvailableOB'
                ])}

                <h2>Home Address</h2>

                <EditAddress
                    address={user.homeAddress || {}}
                    updateFields={(fields, callback) => this.updateAddressFields('homeAddress', fields, callback)}
                />

                {this.form.render('isDifferentBaseAddress')}

                {user.isDifferentBaseAddress && <>
                    <h2>Base Address</h2>

                    <EditAddress
                        {...this.props}
                        address={user.baseAddress || {}}
                        updateFields={(fields, callback) => this.updateAddressFields('baseAddress', fields, callback)}
                    />
                </>}

                {this.renderRotas()}

            </fieldset>

        </>);
    }

    renderRotas() {
        const { user } = this.state;
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        return (<>

            <h2>Working Days</h2>

            <table className="table table-bordered user-rotas-table">
                <thead>
                    <tr>
                        <th className="day-col">Day</th>
                        <th className="is-working-col"></th>
                        <th className="start-time-col">Start</th>
                        <th className="finish-time-col">Finish</th>
                    </tr>
                </thead>
                <tbody>
                    {daysOfWeek.map(dow => {
                        const userRota = user.rotas[dow] || {};

                        return (
                            <tr key={dow}>
                                <td className="day-col">
                                    {dow}
                                </td>
                                <td className="is-working-col">
                                    <input
                                        type="checkbox"
                                        checked={!!user.rotas[dow]}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                this.addRotaDay(dow);
                                            } else {
                                                this.removeRotaDay(dow);
                                            }
                                        }}
                                    />
                                </td>
                                <td className="start-time-col">
                                    <TimeInput
                                        className="form-control"
                                        value={userRota.startTime || ''}
                                        onChange={value => this.updateRota(dow, { startTime: value })}
                                    />
                                </td>
                                <td className="finish-time-col">
                                    <TimeInput
                                        className="form-control"
                                        value={userRota.finishTime || ''}
                                        onChange={value => this.updateRota(dow, { finishTime: value })}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

        </>);
    }
}

export default EditUserModal;