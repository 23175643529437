import React from 'react';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditNonBookingApptTypeModal from 'components/setup/EditNonBookingApptTypeModal';

//-----------------------------------------------------------------

class SetupBooking extends React.Component {

    constructor(props) {
        super(props);
        
        this.editNonBookingApptTypeModalRef = React.createRef();

        this.state = {
            isLoading: true,
            isLoadingNonBookingApptTypes: true
        };

        this.form = new FormHelper({
            fields: {
                travelTimeGreen: {
                    label: 'Map - lines show green when travel time X minutes or under',
                    type: 'number'
                },
                travelTimeRed: {
                    label: 'Red when X minutes or over',
                    type: 'number'
                },
                showTravelTime: {
                    boxLabel: 'Show a block for travel time on the Day Sheet',
                    type: 'checkbox'
                },
                showTravelTimeAfterXMins: {
                    label: 'Show a block if travel time if at least X minutes',
                    type: 'number'
                },
                showWarningOverXMins: {
                    label: 'Show a warning if selected time adds X minutes or more to total travel time',
                    type: 'number'
                },
                bookingTimeSlotType: {
                    label: 'Time Slot Type',
                    type: 'single-select',
                    getOptions: () => [
                        { value: 'TimeRange', text: 'Arrival Time Range (-30min to +30min)' },
                        { value: 'SpecificTime', text: 'Specific Arrival Time' },
                        { value: 'AMorPM', text: 'AM or PM Arrival Time' },
                        { value: 'Special3Slots', text: 'Three slots - 7:30 – 8:30 / 8:15 – 12:00 / 12:00 – 4:45' },
                    ]
                },
                apCostPerDay: {
                    label: 'Cost for booking into the future (per day)',
                    type: 'number'
                },
                apEmptyDayPenalty: {
                    label: 'Cost penalty for booking on an empty day',
                    type: 'number'
                },
                apTravelTimeCost: {
                    label: 'Travel cost (per minute)',
                    type: 'number'
                },
                apRedTravelTimePenalty: {
                    label: 'Cost penalty for Red travel time',
                    type: 'number'
                },
                apGapCost: {
                    label: 'Cost penalty for gap caused between appointments (per minute)',
                    type: 'number'
                },
                apMaxCostBandA: {
                    label: 'Maximum cost for Great',
                    type: 'number'
                },
                apMaxCostBandB: {
                    label: 'Maximum cost for Good',
                    type: 'number'
                },
                apMaxCostBandC: {
                    label: 'Maximum cost for OK',
                    type: 'number'
                },
                apMaxCostOB: {
                    label: 'Maximum cost for Online Booking',
                    type: 'number'
                },
                numDaysAheadForFutureCost: {
                    label: 'Cost for future booking only applies after X days',
                    type: 'number'
                },
                normaliseSuggestions: {
                    boxLabel: 'Normalise suggestions (gives results more often, although this may include worse suggestions)',
                    type: 'checkbox'
                },
                useSimplifiedLookup: {
                    boxLabel: 'Used simplified distance lookup (slightly reduces quality of results but is a LOT quicker)',
                    type: 'checkbox'
                },
                bookingAgentAccountIDs: {
                    type: 'checkboxes',
                    label: 'Booking agent(s)',
                    wrapperClassName: 'checkboxes',
                    getOptions: () => this.state.bookingAgents.map(ba => ({ value: ba.id, text: ba.name }))
                },
                emailCertForInvoiceAppt: {
                    type: 'checkbox',
                    boxLabel: 'Email certificate by default for invoice appointments'
                },
                isOnlineBookingEnabled: {
                    type: 'checkbox',
                    boxLabel: 'Enable Online Booking'
                },
                takeDepositsOB: {
                    type: 'checkbox',
                    boxLabel: 'Take deposits using Stripe'
                },
                obDepositAmount: {
                    type: 'currency',
                    label: 'Deposit amount'
                },
                stripePublicKey: {
                    type: 'text',
                    label: 'Stripe Publishable Key'
                },
                stripeSecretKey: {
                    type: 'password',
                    label: 'Stripe Secret Key'
                }
            },
            getValue: (fieldName) => this.state.account[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.props.fns.setTitle('Booking setup');
        this.load();
    }

    async load() {
        const account = await API.call('account/get-current');
        const bookingAgents = await API.call('account/list-booking-agents');
        this.setState({
            isLoading: false,
            account,
            bookingAgents
        }, () => {
            this.loadNonBookingApptTypes();
        });
    }

    async save() {
        const { account } = this.state;
        this.setState({ isLoading: true });
        await API.call('account/save-current/booking', account);
        this.props.fns.checkLogin();
        this.props.history.replace('/setup');
    }   

    updateFields(fields, callback) {
        const account = { ...this.state.account };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            account[fieldName] = value;
        }
        this.setState({ account }, callback);
    }

    async loadNonBookingApptTypes() {
        this.setState({ isLoadingNonBookingApptTypes: true });
        const nonBookingApptTypes = await API.call('non-booking-appt-type/list');
        this.setState({
            nonBookingApptTypes,
            isLoadingNonBookingApptTypes: false
        });
    }

    async addNonBookingApptType() {
        await this.editNonBookingApptTypeModalRef.current.open({
        });
        this.load();
    }
    
    async editNonBookingApptType(id) {
        await this.editNonBookingApptTypeModalRef.current.open({
            id
        });
        this.load();
    }

    updateDaySheetApptInfo(index, value) {
        const daySheetApptInfos = [ ...this.state.account.daySheetApptInfos ];
        if (index == -1) {
            daySheetApptInfos.push(value);
        } else if (value) {
            daySheetApptInfos[index] = value;
        } else {
            daySheetApptInfos.splice(index, 1);
        }
        this.updateFields({ daySheetApptInfos });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>Booking</h1>

                    <button type="submit" className="ms-auto btn btn-primary">
                        Save
                    </button>

                </section>

                <div className="row">

                    <div className="col-md-6">

                        {this.renderRoutePlanningSection()}

                    </div>

                    <div className="col-md-6">

                        {this.renderBookingOptionsSection()}

                        {this.renderOnlineBookingSection()}

                    </div>

                </div>

            </form>

            {this.renderNonBookingApptTypesSection()}

        </>);
    }

    renderRoutePlanningSection() {
        const { account } = this.state;

        return (
            <section>

                <fieldset>

                    <h2>Route Planning</h2>

                    {this.form.render([
                        'travelTimeGreen',
                        'travelTimeRed',
                        'showTravelTime',
                        account.showTravelTime && 'showTravelTimeAfterXMins',
                        'showWarningOverXMins'
                    ])}

                    <h2>Suggested dates/times</h2>

                    {this.form.render([
                        'apCostPerDay',
                        'numDaysAheadForFutureCost',
                        'apEmptyDayPenalty',
                        'apTravelTimeCost',
                        'apRedTravelTimePenalty',
                        'apGapCost',
                        'apMaxCostBandA',
                        'apMaxCostBandB',
                        'apMaxCostBandC',
                        'apMaxCostOB',
                        'useSimplifiedLookup',
                        'normaliseSuggestions'
                    ])}

                </fieldset>

            </section>
        );
    }

    renderBookingOptionsSection() {
        const { account } = this.state;

        return (
            <section>

                <fieldset>

                    <h2>Booking Options</h2>

                    {this.form.render([
                        'bookingTimeSlotType',
                        'emailCertForInvoiceAppt',
                        'bookingAgentAccountIDs'
                    ])}

                    {this.renderDaySheetApptInfos()}

                </fieldset>

            </section>
        );
    }

    renderNonBookingApptTypesSection() {
        const { isLoadingNonBookingApptTypes, nonBookingApptTypes } = this.state;

        if (isLoadingNonBookingApptTypes) {
            return (
                <section className="control-panel sticky">
                    <Loader />
                </section>
            );
        }
        
        return (<>

            <section>
                
                <h2>
                    Non-Booking Appointment Types

                    <button type="button" className="ms-auto btn btn-primary" onClick={() => this.addNonBookingApptType()}>
                        Add Type
                    </button>

                </h2>
                
                <SuperTable
                    className="non-booking-appt-types-table table table-bordered clickable mb-0"
                    rows={nonBookingApptTypes}
                    keyAccessor={nbat => nbat.id}
                    cellPropsAccessor={(colInfo, nbat) => {
                        if (nbat && colInfo.name == 'colour' && nbat.backColour) {
                            return {
                                style: {
                                    backgroundColor: nbat.backColour,
                                    color: nbat.textColour
                                }
                            };
                        }
                    }}
                    cols={{
                        colour: {
                            className: 'colour-col',
                            getValue: (colInfo, row, formattedValue, index) => `${index + 1}`
                        },
                        name: { label: 'Name', className: 'name-col' },
                        location: { label: 'Location', className: 'location-col' }
                    }}
                    onClick={(nbat, e) => this.editNonBookingApptType(nbat.id)}
                    emptyText="No non-booking appointment types to show"
                />

            </section>

            <EditNonBookingApptTypeModal
                ref={this.editNonBookingApptTypeModalRef}
            />

        </>);
    }

    renderDaySheetApptInfos() {
        const daySheetApptInfos = [...this.state.account.daySheetApptInfos];
        daySheetApptInfos.push(null);

        const options = [
            { value: 'CustomerFullName', text: 'Customer - Full Name' },
            { value: 'CustomerFullNameOrCompany', text: 'Customer - Full Name or Company Name' },
            { value: 'CustomerLastName', text: 'Customer - Last Name' },
            { value: 'CustomerLastNameOrCompany', text: 'Customer - Last Name or Company Name' },
            { value: 'CustomerCompany', text: 'Customer - Company Name' },
            { value: 'PropertyLine1', text: 'Property - Line 1' },
            { value: 'PropertyLine2', text: 'Property - Line 2' },
            { value: 'PropertyTown', text: 'Property - Town' },
            { value: 'PropertyPostcode', text: 'Property - Postcode' },
            { value: 'ApptSummary', text: 'Job Summary' },
            { value: 'ApptTime', text: 'Time' },
            { value: 'SweepNickname', text: 'Sweep Name' }
        ];
        return (
            <div className="form-group">
                <label className="form-label">Day sheet appointment information</label>
                <table className="table table-bordered mb-0">
                    <tbody>
                        {daySheetApptInfos.map((dsai, index) =>
                            <tr key={index}>
                                <td>
                                    <select
                                        className="form-control"
                                        value={dsai || ''}
                                        onChange={e => this.updateDaySheetApptInfo(index, e.target.value)}
                                    >
                                        <option value="">{index == daySheetApptInfos.length - 1 ? '(Add...)' : '(Remove)'}</option>
                                        {options.map(o =>
                                            <option key={o.value} value={o.value}>{o.text}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    renderOnlineBookingSection() {
        const { account } = this.state;

        return (
            <section>

                <fieldset>
                    <h2>Online Booking</h2>

                    {this.form.render([
                        'isOnlineBookingEnabled',
                        account.isOnlineBookingEnabled && 'takeDepositsOB',
                        account.isOnlineBookingEnabled && 'obDepositAmount',
                        account.isOnlineBookingEnabled && account.takeDepositsOB && 'stripePublicKey',
                        account.isOnlineBookingEnabled && account.takeDepositsOB && 'stripeSecretKey'
                    ])}
                </fieldset>

            </section>
        );
    }
}

export default SetupBooking;