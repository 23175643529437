import React from 'react';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditServiceTypeModal from 'components/setup/EditServiceTypeModal';

//-----------------------------------------------------------------

class SetupServiceTypes extends React.Component {

    constructor(props) {
        super(props);

        this.editServiceTypeModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Services setup');
        this.load();
    }

    async load() {
        const serviceTypes = await API.call('service-type/list', { all: true, getPricing: true });
        this.setState({
            isLoading: false,
            serviceTypes
        });
    }

    async open(id) {
        await this.editServiceTypeModalRef.current.open({
            id
        });
        this.load();
        this.props.fns.checkLogin();
    }

    async add() {
        await this.editServiceTypeModalRef.current.open({
        });
        this.load();
        this.props.fns.checkLogin();
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            serviceTypes
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel sticky">

                <h1>Services</h1>

                <button type="button" className="ms-auto btn btn-primary" onClick={() => this.add()}>
                    Add Service
                </button>

            </section>

            <section>

                <SuperTable
                    className="service-types-table table table-bordered clickable mb-0"
                    rows={serviceTypes}
                    keyAccessor={serviceType => serviceType.id}
                    cols={{
                        name: { label: 'Name' },
                        isEnabled: {
                            label: 'Enabled',
                            getValue: (colInfo, serviceType) => <>
                                {serviceType.isEnabled && <span className="fa-solid fa-check" />}
                                {serviceType.isMandatory && <>{' '}<span className="fa-solid fa-lock" title="Mandatory" /></>}
                            </>
                        },
                        priceRangeFirst: {
                            label: 'Price (1st)',
                            className: 'price-col',
                            getValue: (colInfo, serviceType) => {
                                if (!serviceType.isEnabled) {
                                    return null;
                                }
                                const from = TextHelpers.formatCurrency(serviceType.lowestFirstPrice);
                                const to = TextHelpers.formatCurrency(serviceType.highestFirstPrice);
                                return (from == to ? `${from}` : `${from} - ${to}`);
                            }
                        },
                        priceRangeSecondPlus: {
                            label: 'Price (2nd+)',
                            className: 'price-col',
                            getValue: (colInfo, serviceType) => {
                                if (!serviceType.isEnabled) {
                                    return null;
                                }
                                const from = TextHelpers.formatCurrency(serviceType.lowestSecondPlusPrice);
                                const to = TextHelpers.formatCurrency(serviceType.highestSecondPlusPrice);
                                return (from == to ? `${from}` : `${from} - ${to}`);
                            }
                        },
                        // TODO uncomment this when enabling QuickBooks
                        //isLinkedToQuickbooks: {
                        //    label: 'Linked to Quickbooks',
                        //    getValue: (colInfo, serviceType) => !!serviceType.qbItemID && 
                        //        <span className="fa-solid fa-check" />
                        //}
                    }}
                    onClick={(serviceType, e) => this.open(serviceType.id)}
                    emptyText="No services to show"
                />

            </section>

            <EditServiceTypeModal
                ref={this.editServiceTypeModalRef}
                {...this.props}
            />

        </>);
    }
    
}

export default SetupServiceTypes;