import React from 'react';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import EditAddress from 'components/common/EditAddress';

//-----------------------------------------------------------------

class SetupAccount extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            // TODO uncomment this when enabling QuickBooks
            //isQuickbooksLoading: true
        };

        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Account Name'
                },
                territoryNumber: {
                    label: 'Territory Number'
                },
                tradingName: {
                    label: 'Trading Name'
                },
                companyType: {
                    label: 'Company Type',
                    type: 'single-select',
                    getOptions: () => [
                        { value: 'SoleTrader', text: 'Sole Trader' },
                        { value: 'Limited', text: 'Limited Company' }
                    ]
                },
                companyRegName: {
                    label: 'Registered Company Name'
                },
                companyRegNumber: {
                    label: 'Company Registration Number'
                },
                bankName: {
                    label: 'Bank Name'
                },
                bankAccountName: {
                    label: 'Name on Bank Account'
                },
                sortCode: {
                    label: 'Sort Code'
                },
                accountNumber: {
                    label: 'Account Number'
                },
                website: {
                    label: 'Website Address'
                },
                landlineTel: {
                    label: 'Landline Number'
                },
                mobileTel: {
                    label: 'Mobile Number'
                },
                email: {
                    label: 'Email Address'
                },
                vatRegNumber: {
                    label: 'VAT Number'
                },
                vatRateID: {
                    label: 'VAT Rate',
                    type: 'single-select',
                    blankText: 'Not VAT Registered',
                    getOptions: () => this.state.vatRates.map(vr => ({
                        value: vr.id,
                        text: vr.name
                    }))
                },
                invoiceTerms: {
                    label: 'Invoice terms (days)',
                    type: 'number'
                },
                postcodePrefixes: {
                    label: 'Postcodes covered',
                    type: 'multiline-text',
                    rows: 8
                },
                paymentNotes: {
                    label: 'Additional message',
                    type: 'text'
                },
                enableQuickbooksIntegration: {
                    boxLabel: 'Automatically send invoices to Quickbooks (Don\'t forget to connect)',
                    type: 'checkbox'
                },
                nextInvoiceNumber: {
                    type: 'number',
                    label: 'Next invoice number'
                },
                qbTaxCodeID: {
                    type: 'single-select',
                    label: 'Quickbooks Tax Code',
                    blankText: '(None)',
                    getOptions: () => this.state.qbTaxCodes ?
                        this.state.qbTaxCodes.map(qt => ({ value: qt.id, text: qt.name })) :
                        []
                },
                otherProductItemQBID: {
                    type: 'single-select',
                    label: 'Other/Miscellaneous product QuickBooks item',
                    blankText: '(None)',
                    getOptions: () => this.state.qbItems ?
                        this.state.qbItems.map(qt => ({ value: qt.id, text: qt.name })) :
                        []
                },
                autoEmailInvoiceFromQuickbooks: {
                    type: 'checkbox',
                    boxLabel: 'Use Quickbooks to email invoices'
                },
                quickbooksEmailBCCMe: {
                    type: 'checkbox',
                    boxLabel: 'BCC me into invoice emails from Quickbooks'
                }
            },
            getValue: (fieldName) => this.state.account[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.props.fns.setTitle('Company setup');
        this.load();
    }

    async load() {
        const account = await API.call('account/get-current');
        const vatRates = await API.call('vat-rate/list');
        const paymentMethods = await API.call('payment-method/list-all');
        this.setState({
            isLoading: false,
            account,
            vatRates,
            paymentMethods
        }, this.loadQuickbooks);
    }

    async loadQuickbooks() {
        const qbTaxCodes = await API.call('quickbooks/list-tax-codes');
        const qbItems = await API.call('quickbooks/list-items');
        this.setState({
            isQuickbooksLoading: false,
            qbTaxCodes,
            qbItems
        });
    }

    async save() {
        const { account } = this.state;
        this.setState({ isLoading: true });
        await API.call('account/save-current', account);
        this.props.history.replace('/setup');
    }   

    updateFields(fields, callback) {
        const account = { ...this.state.account };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            account[fieldName] = value;
        }
        this.setState({ account }, callback);
    }

    updateAddressFields(addressField, fields, callback) {
        const address = { ...this.state.account[addressField] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            address[fieldName] = value;
        }
        this.updateFields({
            [addressField]: address
        }, callback);
    }

    togglePaymentMethodAccepted(id) {
        const paymentMethods = [ ...this.state.account.paymentMethods ];
        const index = paymentMethods.findIndex(pm => pm.id == id);
        if (index == -1) {
            paymentMethods.push({
                id,
                isAccepted: true
            });
        } else {
            paymentMethods.splice(index, 1);
        }
        this.updateFields({ paymentMethods });
    }

    updatePaymentMethod(id, fields) {
        const paymentMethods = [ ...this.state.account.paymentMethods ];
        const index = paymentMethods.findIndex(pm => pm.id == id);
        const paymentMethod = { ...paymentMethods[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            paymentMethod[fieldName] = value;
        }
        paymentMethods[index] = paymentMethod;
        this.updateFields({ paymentMethods });
    }

    updateLink(id, fields) {
        const links = [...this.state.account.links];
        let index = links.findIndex(al => al.id == id);
        if (index == -1) {
            index = links.length;
            links.push({
                id
            });
        }
        const link = { ...links[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            link[fieldName] = value;
        }
        links[index] = link;
        this.updateFields({ links });
    }

    confirmRemoveLink(id) {
        const confirm = window.confirm('Are you sure you want to remove this link?');
        if (confirm) {
            this.removeLink(id);
        }
    }

    removeLink(id) {
        const links = [...this.state.account.links];
        let index = links.findIndex(al => al.id == id);
        links.splice(index, 1);
        this.updateFields({ links });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;
        const { loginInfo } = this.props;
        debugger;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>Company</h1>

                    <button type="submit" className="ms-auto btn btn-primary">
                        Save
                    </button>

                </section>

                <div className="row">

                    <div className="col-md-6">

                        {this.renderMainSection()}

                    </div>

                    <div className="col-md-6">

                        {this.renderAddressesSection()}

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">

                        {this.renderBankAndInvoiceSection()}

                    </div>

                    <div className="col-md-6">

                        {this.renderContactSection()}

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">

                        {this.renderTerritorySection()}

                    </div>

                    <div className="col-md-6">

                        {this.renderPaymentMethodsSection()}

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">

                        {this.renderLinksSection()}

                    </div>

                    <div className="col-md-6">


                    </div>

                </div>

            </form>

        </>);
    }

    renderMainSection() {
        const { account } = this.state;

        return (
            <section>

                <fieldset>

                    <h2>Company Details</h2>

                    {this.form.render([
                        'name',
                        'territoryNumber',
                        'tradingName',
                        'companyType',
                        (account.companyType == 'Limited' && 'companyRegName'),
                        (account.companyType == 'Limited' && 'companyRegNumber'),
                        'vatRateID',
                        (!!account.vatRateID && 'vatRegNumber'),
                        'invoiceTerms'
                    ])}

                </fieldset>

            </section>
        );
    }

    renderAddressesSection() {
        const { account } = this.state;

        return (
            <section>

                <fieldset>

                    {account.companyType == 'Limited' && <>
                        <h2>Registered Address</h2>
                        <EditAddress
                            prefix="registered-address-"
                            address={account.registeredAddress || {}}
                            updateFields={(fields, callback) => this.updateAddressFields('registeredAddress', fields, callback)}
                        />
                    </>}
                    
                    <h2>Trading Address</h2>
                    <EditAddress
                        prefix="trading-address-"
                        address={account.tradingAddress || {}}
                        updateFields={(fields, callback) => this.updateAddressFields('tradingAddress', fields, callback)}
                    />

                </fieldset>

            </section>
        );
    }

    renderBankAndInvoiceSection() {
        const { isQuickbooksLoading, account } = this.state;

        return (
            <section>

                <fieldset>

                    <h2>Bank Details</h2>

                    {this.form.render([
                        'bankName',
                        'bankAccountName',
                        'sortCode',
                        'accountNumber'
                    ])}

                    <h2>Invoicing</h2>

                    {this.form.render([
                        'nextInvoiceNumber',
                        // TODO uncomment this when enabling QuickBooks
                        //'enableQuickbooksIntegration',
                        //account.enableQuickbooksIntegration && 'autoEmailInvoiceFromQuickbooks',
                        //account.enableQuickbooksIntegration && 'quickbooksEmailBCCMe'
                    ])}

                    {isQuickbooksLoading ?
                        <Loader isInline className="mb-3" /> :
                        this.form.render([
                            !!account.vatRateID && 'qbTaxCodeID',
                            'otherProductItemQBID'
                        ])
                    }
                    
                </fieldset>

            </section>
        );
    }

    renderContactSection() {
        return (
            <section>

                <fieldset>

                    <h2>Contact Details</h2>

                    {this.form.render([
                        'website',
                        'landlineTel',
                        'mobileTel',
                        'email' 
                    ])}

                </fieldset>

            </section>
        );
    }

    renderTerritorySection() {
        return (
            <section>

                <fieldset>

                    <h2>Territory</h2>

                    {this.form.render([
                        'postcodePrefixes'
                    ])}

                </fieldset>

            </section>
        );
    }

    renderPaymentMethodsSection() {
        const { paymentMethods, account } = this.state;

        return (
            <section>

                <fieldset>

                    <h2>Payment Methods Accepted</h2>

                    <table className="table table-bordered payment-methods-accepted-table">
                        <tbody>
                            {paymentMethods.map(pm => {
                                const acctPaymentMethod = account.paymentMethods.find(apm => apm.id == pm.id);
                                return (
                                    <tr key={pm.id} onClick={e => this.togglePaymentMethodAccepted(pm.id)}>
                                        <td className="is-accepted-col">
                                            <input
                                                type="checkbox"
                                                checked={!!acctPaymentMethod}
                                                readOnly
                                            />
                                        </td>
                                        <td className="name-col">
                                            {pm.name}
                                        </td>
                                        <td className="extra-text-col">

                                            {!!acctPaymentMethod &&
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Extra info"
                                                    value={acctPaymentMethod.extraText || ''}
                                                    onChange={e => this.updatePaymentMethod(pm.id, { extraText: e.target.value })}
                                                />
                                            }

                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {this.form.render([
                        'paymentNotes'
                    ])}

                </fieldset>

            </section>
        );
    }

    renderLinksSection() {
        const { account } = this.state;
        const links = [...account.links];
        links.push({ isNew: true, id: -(links.length + 1) });

        return (
            <section>

                <fieldset>

                    <h2>Links</h2>

                    <table className="table table-bordered links-table">
                        <thead>
                            <tr>
                                <th className="type-col">Type</th>
                                <th className="url-col">URL</th>
                                <th className="actions-col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {links.map(al =>
                                <tr key={al.id}>
                                    <td className="type-col">

                                        <select
                                            className="form-control"
                                            value={al.type || ''}
                                            onChange={e => this.updateLink(al.id, { type: e.target.value })}
                                        >
                                            {al.isNew &&
                                                <option value="">(Select)</option>
                                            }
                                            <option value="Checkatrade">Checkatrade</option>
                                            <option value="Facebook">Facebook</option>
                                            <option value="Which">Which</option>
                                            <option value="Yell">Yell</option>
                                            <option value="GoogleReview">Google Review</option>
                                            <option value="">Other</option>
                                        </select>
                                        
                                    </td>
                                    <td className="url-col">

                                        {!al.isNew &&
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="URL"
                                                value={al.url || ''}
                                                onChange={e => this.updateLink(al.id, { url: e.target.value })}
                                            />
                                        }

                                        {!al.isNew && !al.type &&
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Image URL"
                                                value={al.imageURL || ''}
                                                onChange={e => this.updateLink(al.id, { imageURL: e.target.value })}
                                            />
                                        }
                                    </td>

                                    <td className="actions-col">

                                        {!al.isNew &&
                                            <button className="btn btn-danger" onClick={e => this.confirmRemoveLink(al.id)}>
                                                <span className="fa-solid fa-times" />
                                            </button>
                                        }

                                    </td>

                                </tr>
                            )}
                        </tbody>
                    </table>

                </fieldset>

            </section>
        );
    }

}

export default SetupAccount;